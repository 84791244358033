<template>
  <PageSeoData>
    <Link
      rel="preload"
      fetchpriority="high"
      as="image"
      href="/image/suggestionSection/phone-mob@2x.webp"
      type="image/webp"
    />
    <Link
      rel="preload"
      fetchpriority="high"
      as="image"
      href="/image/suggestionSection/phone-mob.webp"
      type="image/webp"
    />
    <Link
      rel="preload"
      fetchpriority="high"
      as="image"
      href="/image/suggestionSection/phone-tab.webp"
      type="image/webp"
    />
    <Link
      rel="preload"
      fetchpriority="high"
      as="image"
      href="/image/suggestionSection/phone-tab@2x.webp"
      type="image/webp"
    />
    <Link
      rel="preload"
      fetchpriority="high"
      as="image"
      href="/image/suggestionSection/phone.webp"
      type="image/webp"
    />
    <Link
      rel="preload"
      fetchpriority="high"
      as="image"
      href="/image/suggestionSection/phone@2x.webp"
      type="image/webp"
    />
    <template #scripts>
      <Link
        rel="stylesheet"
        :href="'https://api.mapbox.com/mapbox-gl-js/v2.15.0/mapbox-gl.css'"
        fetchpriority="low"
        defer
        media="screen and (min-width: 1280px)"
      />
    </template>
  </PageSeoData>
  <SuggestionSection :page="User.members" :is-statistics="true">
    <template #images>
      <PictureComponent
        :modern-format-image="{
          'mobile-path': '/image/suggestionSection/phone-mob.webp',
          'mobile-path-retina': '/image/suggestionSection/phone-mob@2x.webp',
          'tablet-path': '/image/suggestionSection/phone-tab.webp',
          'tablet-path-retina': '/image/suggestionSection/phone-tab@2x.webp',
          'desktop-path': '/image/suggestionSection/phone.webp',
          'desktop-path-retina': '/image/suggestionSection/phone@2x.webp',
        }"
        :fallback-format-image="{
          'mobile-path': '/image/suggestionSection/phone-mob.png',
          'mobile-path-retina': '/image/suggestionSection/phone-mob@2x.png',
          'tablet-path': '/image/suggestionSection/phone-tab.png',
          'tablet-path-retina': '/image/suggestionSection/phone-tab@2x.png',
          'desktop-path': '/image/suggestionSection/phone.png',
          'desktop-path-retina': '/image/suggestionSection/phone@2x.png',
        }"
        loading="auto"
        fetchpriority="high"
      />
    </template>
  </SuggestionSection>
  <div class="section-wrapper" id="advantagesSection">
    <LazySectionsAdvantagesSection v-if="sectionsState.advantagesSection" brand="Allsports" />
  </div>
  <div ref="levelsSection" class="section-wrapper" id="levelsSection">
    <LazySectionsLevelSection
      :benefit-parameters="{ levels }"
      v-if="sectionsState.levelsSection"
    />
  </div>
  <div class="section-wrapper" id="feedbackSection">
    <LazySectionsFeedbackSection
      :page="User.companies"
      :partner-companies="companies"
      v-if="sectionsState.feedbackSection"
    />
  </div>
  <div class="section-wrapper" id="faqSection">
    <LazySectionsFAQSection v-if="sectionsState.faqSection" />
  </div>
  <div class="section-wrapper" id="videoSection">
    <LazySectionsVideoSection
      v-if="sectionsState.videoSection"
      :video-link="videoLink"
      loading="lazy"
    />
  </div>
  <div class="section-wrapper" id="getDetailsSection">
    <LazySectionsGetDetailsSection v-if="sectionsState.getDetailsSection" />
  </div>
  <div class="section-wrapper" id="contactsSection">
    <LazySectionsContactsSection
      :office-contact="officeContact"
      v-if="sectionsState.contactsSection"
    />
  </div>
</template>

<script setup lang="ts">
import getOrganizationScheme from "~/schemas/OrganizationScheme";
import getFAQScheme from "~/schemas/FAQScheme";

import { Brand, User } from "~/types/entities";

import SuggestionSection from "~/components/sections/SuggestionSection.vue";
import PageSeoData from "~/components/pageSeoData/PageSeoData.vue";
import { Content } from "~/stores/content";

const { brand } = useRuntimeConfig().public;

const sectionsState = reactive({
  advantagesSection: false,
  levelsSection: false,
  feedbackSection: false,
  faqSection: false,
  videoSection: false,
  getDetailsSection: false,
  contactsSection: false,
});

const { levels, companies, officeContact, videoLink } = useAttrs()
  .content as Content;

useSchemaOrg([getOrganizationScheme(), getFAQScheme(User.members)]);

const observer = process.client ? new IntersectionObserver(onIntersect) : null;

function onIntersect(entries: IntersectionObserverEntry[]) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      sectionsState[entry.target.id] = true;
      observer?.unobserve(entry.target);
    }
  });
}

onMounted(() => {
  const sections = document.querySelectorAll(".section-wrapper");
  sections.forEach((section) => observer?.observe(section));
});
</script>
